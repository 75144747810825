import React, { useRef, useState } from "react";
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "@components/layout"
import Seo from "@components/seo"
import "@css/@wordpress/block-library/build-style/style.css"
import "@css/@wordpress/block-library/build-style/theme.css"
import parse from "html-react-parser"

// コンポーネント呼び出し
import { BlogGraphqlArray } from "@components/graphql-data-blog.js"; // 記事情報取得・形成
import { FixedFoot } from "@components/footer";
import { WordSearch } from "@components/word-search.js";
import { SearchCategList } from "@components/search-categList.js"; // カテゴリ検索機能

const BlogPost = ({ data: { post, allWpPost, wp }, pageContext }) => {
  const contentEl = useRef();
  const indexUrl = '/';
  const typeUrl = 'blog/';
  const name = pageContext.name; // 該当記事名
  const slug = pageContext.slug; // 該当Slug
  const searchList = SearchCategList().Main; // カテゴリ検索リスト(components/search-categList.js)
  const allPostData = BlogGraphqlArray('', 'allandMain');
  const allData = allPostData.allData;
  const mainCategs = allPostData.mainCateg;

  const contentData = post.content;
  

  // 該当記事情報取得(components/graphql-data-blog.js)
  let setArray;
  for (let i = 0; i < allData.length ; i++){
    if (allData[i].Title === name){
      setArray = allData[i]; 
    }
  }

  // 表示データ
  const date = setArray.Date; // 作成日
  const modified = setArray.Modified; // 更新日
  const featuredImage = { // 記事画像
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.altText || ``,
  }

  // 大カテゴリ、中カテゴリ選定
  let setMainCateg, setMainCategSlug, result;
  // let setSubCateg
  for (let i = 0; i < setArray.Categories.length ; i++){
    result = mainCategs.find(item => item === setArray.Categories[i])
    if ( result === setArray.Categories[i] ) {
      setMainCateg = setArray.Categories[i]
      setMainCategSlug = setArray.CategsSlug[i]
    } else {
      // setSubCateg = setArray.Categories[i]
    }
  }
  // 検索窓
  const wordSearchContent = WordSearch(indexUrl, typeUrl, setMainCategSlug).Blog; // ワード検索Blog

  // カテゴリコンテンツ（右側）
  const [buttonClicked, setButtonClicked] = useState(false); // アコーディオン初期表示用
  const classToggle = () => {
    setButtonClicked(true);
  };
  const [clicked, setClicked] = useState(100); // アコーディオンの開閉設定
  const handleClick = (index) => {
    if (clicked === index) {return setClicked(100);}
    setClicked(index);
  };
  let setPost, setData;
  let categArray = [];
  const mainCategArray = BlogGraphqlArray('', 'header').mainCategUrl;
  const sortArray = [...mainCategArray.filter(arr => arr[0] === setMainCateg), ...mainCategArray.filter(arr => arr[0] !== setMainCateg)];
  for (let j = 0; j < sortArray.length ; j++){
    for (let i = 0; i < setArray.Categories.length ; i++){
      let blogArray = BlogGraphqlArray(sortArray[j][0], 'setCateg').checkCateg;
      setPost = blogArray.map((result) => {
        return  <>
                  {/* タイトル名部分 */}
                  <a className="hover:bg-[#888888] hover:text-white block rounded-sm" href={indexUrl + typeUrl + result.Slug + "/"}>
                    <p className="py-1 px-3" >{result.Title}</p>
                  </a>
                </> ;
                
      });
      if ( j === 0 ) {
        setData = [setPost, sortArray[j][0], "!rounded-t-sm", indexUrl + sortArray[j][1] + "/", " !block", " bg-[#0055AC] text-white"]
      } else if (j === 4) {
        setData = [setPost, sortArray[j][0], "!rounded-b-sm", indexUrl + sortArray[j][1] + "/", " ", " bg-white text-[#0055AC]"]
      } else {
        setData = [setPost, sortArray[j][0], "", indexUrl + sortArray[j][1] + "/", " ", " bg-white text-[#0055AC]"]
      }
    }
    categArray.push(setData)
  }
  let setMBmenu = categArray.map((result, index) => {
    return  <div className={"accordion-item bg-none " + result[2]}>
              <div key={index} className="mb-0">
                <button type="button" onClick={() => {handleClick(index); classToggle(); }} 
                  className={"rounded relative flex items-center justify-between w-full text-base text-left transition p-4 " + result[5]}>
                  <a href={result[3]} className="z-10">{result[1]}</a>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                         className={"w-6 h-6 duration-200 " + (clicked === index ? "rotate-180" : "")}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                </button>
                <div ref={contentEl} className={"py-2 text-[#0055AC]" + (clicked === index ? " " : " hidden") + (buttonClicked === true ? ' ' : result[4])}>
                  {result[0]}
                </div>
              </div>
            </div>;
  });
  
  const fixedItem = FixedFoot( 'nomal', '' ); // 固定フッター
  // const textContents = SsComponent( contentData, idArray, hrefArray ); // 本文情報とid情報引き渡し  
  
  return (
  <>
    <Layout>
      <Seo
        title={post.title}
        description={setArray.Description}
        ogpUrl={process.env.ORIGINURL + indexUrl + slug + '/'}
        ogpSiteName={post.title}
        ogpImage={post.featuredImage?.node?.guid || ''}
        ogpImageWidth={post.featuredImage?.node?.width || ''}
        ogpImageHeight={post.featuredImage?.node?.height || ''}
        ogpImageType={post.featuredImage?.node?.mimeType || ''}
        modifiedDate={post.modified || ''}
        publishedDate={post.date || ''}
        wpPost = "true"
      />
      
      <article className="blog-post" itemScope itemType="http://schema.org/Article">

        <div className="bg-[#F6F6F6] md:pb-[45px] pb-2">
          <div className="px-5 max-w-xl mx-auto md:max-w-5xl ">
            <div className="pb-4 pt-0 md:pt-10  pt-0  max-w-[800px] ">
              <h2 className="md:text-5xl text-3xl font-black mb-5 text-left pt-5 !leading-[1.2]">学ぶ</h2>
              <h3 className="md:text-3xl text-2xl font-black  text-left  text-[#1F55A6]">
                プロップファームを利用する際に必要になる<br></br>注意点や基本的知識などを掲載しています。</h3>
            </div>
          </div>
        </div>

        <div className="md:max-w-5xl md:flex justify-between mx-auto pt-5 md:pt-12"> 
          {/* 左カラム */}
          <div className="max-w-[730px] w-full pb-5 px-5 mb-10 md:pb-0 md:mb-0">
            {/* タイトル */}
            <div className="text-left mb-10">
              <h2 className=" font-semibold tracking-wide text-[20px] md:text-[26px] text-[#1F55A6] table" itemProp="headline">{ name }</h2>
            </div>
            {/* 作成日、更新日 */}
            <p className="text-[11px] mb-3 flex ">
              <span className="test100 px-2">作成日：{date}</span>
              <span className="px-2">更新日：{modified}</span>
            </p>
            {/* 画像 */}
            <div className="mt-0  rounded-blog max-w-[730px] w-full" >
              {featuredImage?.data && (
                <GatsbyImage className=" w-full max-w-[730px]" image={featuredImage.data} alt={featuredImage.alt}/>   
              )}
            </div>
            {/* 本文 */}
            <div className="md:my-0 md:mx-auto" style={{ paddingTop: 24, paddingBottom: 24, maxWidth: 976}}>
              {!!post.content && (
                <section itemProp="articleBody">{parse(contentData)}</section>
              )}
            </div>
          </div>

          {/* 右カラム */}
          <div className="column2_filed px-2 bg-[#F6F6F6] py-2 mb-[30%] rounded-md">
            {/* カテゴリリスト */}
            {setMBmenu}
          </div>

        </div>
        <div>
          {/*ワード検索 */}
         
          {/* MBプロフィール */}
          
        </div>
      </article>

      {/* ページネーション */}
      <div className="border-t-[#D0D0D0] border-t-[0.6px] border-solid">
        <a href={indexUrl + typeUrl + setMainCategSlug + "/"} className="flex px-5 py-10 md:text-sm">
          <nav className="font-bold blog-post-nav w-full my-0 mx-auto" style={{ maxWidth: 976 }}>
            <ul className="" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', listStyle: 'none', padding: 0 }}>
              記事一覧へ戻る
            </ul>
          </nav>
        </a>
      </div>
      
      {/* 検索窓 */}
      <div className="bg-[#F6F6F6] pt-[30px]">
        <div className="max-w-xl mx-auto md:max-w-5xl px-5 ">
          <p className="font-bold mb-[30px]">記事検索</p>
          <div className="md:flex pb-[45px]">
            <div className="md:w-[50%]">{wordSearchContent}</div>
            <div className="md:w-[50%]">{searchList}</div>
          </div>
        </div>
      </div>	

      {/* 固定フッター */}
      {fixedItem}
    </Layout>
  </>
  )
}
export default BlogPost

export const pageQuery = graphql`
  query FxBlogPostById(
    $id: String!
  ) {
    allWpBlog(
      sort: { fields: date, order: DESC }
    ) {
      pageInfo {
        totalCount
      }
      edges {
        node {
          id
          uri
          title
          blogCategory {
            nodes {
              name
            }
          }
          blogTag {
            nodes {
              name
            }
          }
        }
      }
    }
    post: wpBlog(id: { eq: $id }) {
      id
      excerpt
      content
      title
      uri
      modified
      blogCategory {
        nodes {
          name
        }
      }
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          guid
          mimeType
          width
          height
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  }
`